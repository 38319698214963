











































import User, { SubscriptionType } from "@/utility/user";
import Vue from "vue";
import { Component, Prop, PropSync } from "vue-property-decorator";

@Component
export default class ChangeUserSubscriptionPlanDialog extends Vue {
  // 🚩 Flag which determines whether the dialog should be shown
  @PropSync("showDialog", { type: Boolean })
  public show!: boolean;

  // 📦 The user which should have their subscription plan changed
  @Prop()
  public user!: User;

  // 📦 The Stripe subscription ID that should be attached for the user in order to enable Stripe to work properly
  private subscriptionId: string | null = null;

  // 📦 The subscription tiers that can be manually switched in between
  private get availableSubscriptionTiers(): string[] {
    return this.$store.getters.switchableSubscriptionTiers;
  }

  /**
   * 🔨 Update the user's subscription tier
   */
  private async updateUserSubscriptionTier() {
    this.$store.dispatch("updateUserSubscriptionTier", {
      email: this.user.email,
      freePro: this.user.subscription === SubscriptionType.FreePro,
      planToUpgradeTo: this.user.subscription,
      stripeSubscriptionId: this.subscriptionId
    });
  }
}
